<template>
  <!-- <nav-bar-collapsed v-if="this.$vuetify.breakpoint.smAndDown"></nav-bar-collapsed> -->
  <nav-bar-full></nav-bar-full>
</template>

<script>
import NavBarFull from './NavBarFull.vue';

export default {
  components: {NavBarFull},
  name: "NavBar",
};
</script>

<style scoped>

</style>
