<template>
  <v-footer padless>
    <v-img class="full-height" :src="getAssetImg('photos/BlackFooter.jpg')">
      <v-row class="fill-height">
        <v-col>
          <v-container class="fill-height">
            <v-row>
              <v-col cols="8" offset="2" align="center">
                <p>
                  <router-link :to="{name: 'products'}" class="white--text">Products</router-link>
                </p>
                <p>
                  <router-link :to="{name: 'find'}" class="white--text">Find Us</router-link>
                </p>
                <p><a class="white--text">Contact</a></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8" offset="2" align="center" class="white--text">
                <p>contact@goodbev.co</p>
                <p>Anaheim, California 92806</p>
                <p>Please drink responsibly. Keep out of reach of kids.</p>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-img>
  </v-footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped>
.full-height {
    height: 400px;
}
</style>
