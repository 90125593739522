<template>
  <div>
    <v-app-bar app dark class="stripes">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <div id="logoContainer" style="padding-top: 40px">
        <router-link to="/">
          <v-img
            :src="getAssetImg('logo/Logo_Regular.png')"
            height="100px"
            width="180px"
            style="transform: rotate(-8deg); "
            contain
          ></v-img>
        </router-link>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" left temporary app>
      <v-list nav>
        <v-list-item @click.stop="drawer = !drawer">
          <v-list-item-title align="right">
            <v-icon color="red"> mdi-close</v-icon>
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'home' }">
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'find' }">
          <v-list-item-title>Find Us</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'products' }">
          <v-list-item-title>Shop</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'login' }">
          <v-list-item-title>Retailer Login</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  components: {},
  name: "NavBarFull",
  data() {
    return {
      drawer: false,
    };
  },
};
</script>

<style scoped>

</style>
